var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-rating",
      class: {
        _disabled: _vm.disabled,
      },
      style: {
        color: _vm.color,
      },
    },
    [
      _c(
        "ul",
        { staticClass: "product-rating__list" },
        _vm._l(_vm.maxStars, function (star) {
          return _c("RatingStarsItem", {
            key: star,
            staticClass: "product-rating__list-star",
            attrs: {
              star: star,
              stars: _vm.stars,
              color: _vm.color,
              size: _vm.size,
            },
            on: {
              "star-click": function () {
                return _vm.rate(star)
              },
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }