<template>
  <div
    class="product-rating"
    :class="{
      _disabled: disabled
    }"
    :style="{
      color: color
    }"
  >
    <ul class="product-rating__list">
      <RatingStarsItem
        @star-click="() => rate(star)"
        v-for="star in maxStars"
        :key="star"
        :star="star"
        :stars="stars"
        :color="color"
        :size="size"
        class="product-rating__list-star"
      />
    </ul>
  </div>
</template>
<script>
import RatingStarsItem from '@/components/rating-stars/RatingStarsItem.vue'

export default {
  name: 'RatingStars',
  components: {
    RatingStarsItem
  },
  props: {
    grade: {
      type: [String, Number],
      default: ''
    },
    maxStars: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      stars: this.grade
    }
  },
  watch: {
    grade() {
      if (this.disabled) {
        this.stars = this.grade
      }
    }
  },
  methods: {
    rate(star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star
      }
      this.$emit('stars-rating', this.stars)
    }
  }
}
</script>

<style lang="scss">
.product-rating {
  display: flex;
  color: #e5e5e5;
  &._disabled {
    pointer-events: none;
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0;

    &:hover {
      .product-rating__list-star {
        color: #f0a941;
      }
    }

    &-star {
      display: inline-block;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }

      svg {
        width: 100%;
        max-width: 100%;
        height: 100%;
        fill: currentColor;
      }

      &:hover {
        ~ .product-rating__list-star:not(._active) {
          color: inherit;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &._active {
        color: #fff;
      }
    }
  }
}
</style>
