var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "product-rating__list-star",
      class: { _active: _vm.star <= _vm.stars },
      style: {
        color: _vm.color,
        width: _vm.size + "px",
        height: _vm.size + "px",
      },
      on: {
        click: function () {
          return _vm.$emit("star-click", _vm.star)
        },
      },
    },
    [
      _vm.star <= _vm.stars
        ? _c("svg", { staticClass: "_fill" }, [
            _c("use", { attrs: { "xlink:href": "#ico-star-fill" } }, [
              _c(
                "symbol",
                { attrs: { id: "ico-star-fill", viewBox: "0 0 536 512" } },
                [
                  _c("path", {
                    attrs: {
                      d: "M239.3,17.8L174,150.2L27.9,171.5c-26.2,3.8-36.7,36.1-17.7,54.6l105.7,103l-25,145.5c-4.5,26.3,23.2,46,46.4,33.7\nL268,439.6l130.7,68.7c23.2,12.2,50.9-7.4,46.4-33.7l-25-145.5l105.7-103c19-18.5,8.5-50.8-17.7-54.6L362,150.2L296.7,17.8\nC285-5.8,251.1-6.1,239.3,17.8L239.3,17.8z",
                    },
                  }),
                ]
              ),
            ]),
          ])
        : _c("svg", { staticClass: "_stroke" }, [
            _c("use", { attrs: { "xlink:href": "#ico-star" } }, [
              _c("svg", { attrs: { id: "ico-star", viewBox: "0 0 536 512" } }, [
                _c("path", {
                  attrs: {
                    d: "M508.1,171.5L362,150.2L296.7,17.8c-11.7-23.6-45.6-23.9-57.4,0L174,150.2L27.9,171.5c-26.2,3.8-36.7,36.1-17.7,54.6\nl105.7,103l-25,145.5c-4.5,26.3,23.2,46,46.4,33.7L268,439.6l130.7,68.7c23.2,12.2,50.9-7.4,46.4-33.7l-25-145.5l105.7-103\nC544.8,207.6,534.3,175.3,508.1,171.5L508.1,171.5z M368.6,312.3l23.7,138.4L268,385.4l-124.3,65.3l23.7-138.4l-100.6-98l139-20.2\nl62.2-126l62.2,126l139,20.2L368.6,312.3L368.6,312.3z",
                  },
                }),
              ]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }